.content {
	margin-top: 80px;
	
	&__container {
		
		.page--front & > div.video {
			margin-top: 0;
		}
		
		.page--sub & > div:first-child {
			margin-top: 0;
		}
	}
}