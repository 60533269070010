.page {
	font-size: $font-size;
	font-family: $sans;
	color: $base-font-color;
	background-color: #fff;
	background-size: 100% auto;
	
	overflow-x: hidden;
	
	&--front {
		background-color: $background-color;
		
		.yogi & {
			background-color: #000;
		}
	}
	
	&--sub {}
}

.page--no-scroll {
	overflow: hidden;
}