.hero {
	max-width: $max-width-inner;
	margin: 0 auto;
	
	&__container {
		margin: 0 20px;
	}
	
	&__player {
		background-color: #000;
		margin: 0 auto;
		
		width: 100%;
		padding-bottom: 56.25%;
		
		& iframe {
			width: 100%;
			height: 100%;
			display: none;
		}
	}
}