#GalleryPage {
	max-width: 1320px;
	margin: 0 auto;
}

#GalleryPage h3 {
	font-size:18px;
	color:#fff;
	line-height:24px;
	margin:0;
}

#GalleryPage .teasers .row {
	margin:0px;
}

#GalleryPage .thumbnails {
	list-style:none;
	padding:0;
	margin-top:40px;
}

#GalleryPage .thumbnails li {
	height:55px;
	float:left;
	filter:grayscale(100%);
	-webkit-filter:grayscale(100%);
}

#GalleryPage .thumbnails li.active,#GalleryPage .thumbnails li:hover {
	cursor:pointer;
	background:#fff;
	filter:grayscale(20%);
	-webkit-filter:grayscale(20%);
}

#GalleryPage .thumbnails img {
	width:auto;
	height:100%;
	padding:2px;
}

#GalleryPage .container {
	position:relative;
	min-height:400px;
	padding-bottom:0;
}

#GalleryPage .container.teasers {
	padding-top:0;
}

#GalleryPage .floater {
	position:absolute;
	bottom:24px;
	left:30px;
	top:auto;
	text-align:left;
	display: none;
}

#GalleryPage .floater a {
	text-decoration:none;
}

#GalleryPage .top-background {
	position:relative;
	background-color:rgba(0,0,0,0);
	text-align:center;
	height:calc(100vh - 80px);
	-webkit-transform-style:preserve-3d;
	transform-style:preserve-3d;
}

@-webkit-keyframes rotating {
	from {
		-webkit-transform:rotate(0);
	}
	
	to {
		-webkit-transform:rotate(360deg);
	}
}

@keyframes rotating {
	from {
		-webkit-transform:rotate(0);
		transform:rotate(0);
	}
	
	to {
		-webkit-transform:rotate(360deg);
		transform:rotate(360deg);
	}
}

#GalleryPage .top-background .loader {
	-webkit-animation:rotating 1s linear infinite;
	animation:rotating 1s linear infinite;
	display:none;
	position:absolute;
	left:50%;
	top:50%;
	width:44px;
	height:44px;
	margin-left:-22px;
	margin-top:-22px;
	z-index:100;
}

#GalleryPage .top-background-image {
	height:100%;
	width:auto;
	display:inline-block;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translateX(-50%) translateY(-50%);
	transform:translateX(-50%) translateY(-50%);
}

#GalleryPage .top-background-image img {
	height:100%;
	width:auto;
	position:relative;
	top:50%;
	left:50%;
	-webkit-transform:translateX(-50%) translateY(-50%);
	transform:translateX(-50%) translateY(-50%);
}

#GalleryPage .top-background .watermark {
	content:"";
	width:80px;
	height:80px;
	display:block;
	background-image: image('gallery/gallery-watermark.png');
	background-repeat: no-repeat;
	background-position: left bottom;
	position:absolute;
	opacity:.5;
	right:10px;
	bottom:10px;
}

#GalleryPage .top-background-image.loading .watermark {
	display:none;
}

#GalleryPage .floater h2 {
	color:#fff;
	margin-bottom:0;
	font-size:40px;
	letter-spacing:normal;
}

#GalleryPage .floater a {
	text-transform:uppercase;
	color:#000;
	font-weight:700;
}

#GalleryPage .top-background .nav {
	position:absolute;
	top:50%;
	left:40px;
	background-image: image('gallery/gallery-arrow-left.png');
	background-repeat: no-repeat;
	background-position: left top;
	width:32px;
	height:112px;
	margin-top:-56px;
	opacity:.5;
	z-index:1;
}

#GalleryPage .top-background .nav:hover {
	opacity:1;
}

#GalleryPage .top-background .nav.fwd {
	background-image: image('gallery/gallery-arrow-right.png');
	left:auto;
	right:40px;
}

#GalleryPage .top-background .nav:after {
	padding:200px 50px 200px 100px;
	position:absolute;
	left:-100px;
	top:-100px;
}

#GalleryPage .fb_iframe_widget,#GalleryPage .fb_iframe_widget span,#GalleryPage .fb_iframe_widget span iframe[style] {
	min-width:100%!important;
	width:100%!important;
}

#GalleryPage .container:after,#GalleryPage .container:before {
	content:" ";
	display:table;
	clear:both;
}

#GalleryPage .row {
	margin:40px;
}

#GalleryPage .row:after,#GalleryPage .row:before {
	content:" ";
	display:table;
	clear:both;
}

@media (min-width:768px) {
	#GalleryPage .col-sm-6 {
		float:left;
		overflow:hidden;
	}
	
	#GalleryPage .col-sm-6 {
		width:50%;
	}
}

@media (min-width:992px) {
	#GalleryPage .col-md-4,#GalleryPage .col-md-6 {
		float:left;
		overflow:hidden;
	}
	
	#GalleryPage .col-md-6 {
		width:50%;
	}
	
	#GalleryPage .col-md-4 {
		width:33.33333%;
	}
}

#GalleryPage .container.teasers.full>div>div>div {
	width:100%;
	height:100%;
}

@media (min-width:768px) {
	#GalleryPage .container.teasers.full .row>.col-sm-6 {
		height:740px;
	}
}

#GalleryPage .container.teasers.full .contents {
	position:absolute;
	text-align:center;
	bottom:10%;
	width:100%;
}

#GalleryPage .container.full div {
	background-size:cover;
	background-position:center center;
}

#GalleryPage .container.teasers.full .contents {
	position:absolute;
	text-align:center;
	bottom:10%;
	width:100%;
}

#GalleryPage .container.teasers.full h2 {
	color:#fff;
	line-height:50px;
	font-size:40px;
	margin:0;
	font-weight:700;
	font-family: "Eksell Display Web", serif;
}

#GalleryPage .container.teasers.full>div>div a {
	display:block;
	width:100%;
	height:100%;
}

#GalleryPage .container.teasers.full {
	background-color:#fff;
}

#GalleryPage .container.teasers.full div {
	padding: 0;
}

#GalleryPage .container.teasers.full .zoomBox {
	overflow: hidden;
	padding: 0 20px 40px;
}

@media (min-width:768px) {
	#GalleryPage .container.teasers.full .row>.col-sm-4 {
		height:440px;
	}
	
	#GalleryPage .container.teasers.full .row>.col-sm-6 {
		height:600px;
	}
}

@media (max-width:767px) {
	#GalleryPage .container.teasers.full .col-sm-6 {
		height:600px;
	}
	
	#GalleryPage .container.teasers.full .col-sm-3 {
		height:240px;
	}
}

#GalleryPage .container.teasers.full>div>div.zoomBox div {
	text-shadow:0 2px 10px rgba(0,0,0,.5);
}

#GalleryPage .container.teasers.full>div>div.zoomBox div {
	-webkit-transform:translateZ(0);
	-webkit-backface-visibility:hidden;
	text-shadow:0 2px 10px rgba(0,0,0,.5);
}