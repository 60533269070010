.slides {
	
	margin: 100px 0;
	
	.page--front & {
		margin: 0;
	}
	
	&__container {
		width: 100%;
		max-width: 100%;
		overflow: hidden;
	}
	
	&__backdrop {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		margin-left: 20px;
		padding-top: 60px;
		padding-bottom: 30px;
		
		@include when($xlarge) {
			padding-top: 0;
			padding-bottom: 0;
			margin-left: calc( (100vw - 1280px) / 2);
		}
	}
	
	.page--front &__backdrop {
	}
	
	&__title {
		display: block;
		font-family: $serif;
		white-space: normal;
		margin: 0 40px 0;

		font-weight: normal;
		font-size: 12vw;
		line-height: 12vw;
		
		@include when($medium) {
			font-size: 12vw;
			line-height: 12vw;
		}
				
		@include when($large) {
			position: absolute;
			width: $slides-card-width;
			display: block;
			font-size: 110px;
			line-height: 100px;
			margin:0;
			white-space: normal;
			top: 400px;
			left:50px;
			transform: translateY(-50%);
		}
	}
	
	.page--front &__title {
		font-size: 12vw;
		line-height: 12vw;
		
		@include when($medium) {
			font-size: 12vw;
			line-height: 12vw;
		}

		@include when($large) {
			font-size: 90px;
			line-height: 100px;
		}
	}

	&__scroller {
		overflow: hidden;
		width: 100%;
		height: calc(80px + ( (100vw - 80px) / (5.8/6) ));
		
		@include when($large) {
			height: 780px;
		}
	}
		
	&__cards {
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		-webkit-overflow-scrolling: touch;
		white-space: nowrap;
		
		&::-webkit-scrollbar {
			display: none;
		}
		
		scroll-snap-type: mandatory;
		-webkit-scroll-snap-type: mandatory;
		scroll-snap-points-x: repeat(100%);
		-webkit-scroll-snap-points-x: repeat(100%);
		scroll-snap-destination: 0px 0px;
		-webkit-scroll-snap-destination: 0px 0px;
		
		
		list-style: none;
		padding: 40px 0 60px 0;
		margin: 0;
		display: block;
		position: relative;
		
		@include when($large) {
			padding: 100px 0;
			scroll-snap-points-x: repeat(630px);
			-webkit-scroll-snap-points-x: repeat(630px);
		}
	}
	
	&__card {
		scroll-snap-align: start;
		-webkit-scroll-snap-align: start;
		scroll-snap-coordinate: 0% 0%;
		-webkit-scroll-snap-coordinate: 0% 0%;
		
		display: inline-block;
		vertical-align: top;
		padding-left: 20px;
		
		@include when($large) {
			padding-left: 50px;
		}
				
		&:last-child {
			margin-right: 30px;
	
			@include when($large) {
				margin-right: calc(50vw + 50% - #{$slides-card-width} - 60px);
				
				.no-scrollsnappoints & {
					margin-right: calc(50vw + 50% - #{$slides-card-width} - 20px);
				}
				
				.scrollsnappointsx & {
					margin-right: calc(50vw + 50% - #{$slides-card-width} - 100px);
				}
			}
		}
		
		&--title {
			display: none;
			
			@include when($large) {
				display: inline-block;
			}
		}
		
	}
	
	&__inner {
		width: calc(100vw - 80px);
		height: calc((100vw - 80px)/(580/600));
		text-align: center;
		box-shadow: 20px 20px 20px rgba(0,0,0,.9);
		background-size: cover;
		background-position: center center;
		display: inline-block;
		position: relative;
		
		@include no-select;
				
		@include when($large) {
			width: $slides-card-width;
			height: $slides-card-height;
			box-shadow: 30px 30px 30px rgba(0,0,0,.9);
		}
		
		.slides__card--title & {
			box-shadow: none;
		}
		
		.page--front .slides__card--title {
			margin-left: 40px;
		}
	}
	
	.page--front &__inner {
		width: calc(100vw - 80px);
		height: calc((100vw - 80px)/(580/600));
		
		@include when($large) {
			width: $slides-card-width;
			height: $slides-card-height;
		}
	}
	
	&__wrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding-bottom: 20px;
		padding-top: 50%;
		background-image: linear-gradient(rgba(0,0,0,0) 55%, rgba(0,0,0,0.5) 100%);
		
		@include when($large) {
			padding-bottom: 30px;
		}
	}
	
	&__number {
		margin: 0 auto;
		background-color: $slides-card-number-bgcolor;
		border-radius: $slides-card-number-size;
		width: $slides-card-number-size;
		height: $slides-card-number-size;
		line-height: $slides-card-number-size;
		color: $slides-card-number-color;
		font-weight: bold;
		font-family: $sans;
		font-size: 14px;
		text-align: center;
	}
	
	&__subject {
		font-family: $serif;
		font-size: $font-size-large;
		font-size: 26px;
		line-height: 40px;
		color: $slides-card-color;
		text-shadow: 0px 0px 3px rgba(0,0,0,.3);
		
		@include when($large) {
			line-height: 70px;
			font-size: 40px;
		}
	}
	
	&__desc {
		max-width: 330px;
		width: 90%;
		margin: 0 auto;
		font-family: $sans;
		color: $slides-card-color;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.14em;
		font-weight: bold;
		text-transform: uppercase;
		white-space: normal;
		
		& br {
			display: none;
		}
		
		@include when($large) {
			width: 78%;
			font-size: 14px;
			line-height: 18px;
			
			br {
				display: block;
			}
		}
	}
	
	.dragscroll {
		cursor: grab !important;
		cursor: -moz-grab !important !important;
		cursor: -webkit-grab !important;
	}
	
	.dragscroll:active {
		cursor: grabbing !important;
		cursor: -moz-grabbing !important;
		cursor: -webkit-grabbing !important;
	}
	
	&__shaka {
		content:"";
		width: 100px;
		height: 90px;
		background-image: image('shaka.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		right: 10px;
		top: 50%;
		margin-top: -20px;
		transform: rotate(0deg) translateZ(0);
		backface-visibility: hidden;
		will-change: transform;
		animation: 5s linear infinite jiggle;
		pointer-events: auto;
		cursor: pointer;

		@include when($medium) {
			right: 30px;
			height: 115px;
			margin-top: -57px;
		}
		
		.yogi & {
			right:5px;
			background-image: image('peace.svg');
			height: 90px;
			margin-top: -20px;
			
			@include when($medium) {
				height: 140px;
				margin-top: -30px;
			}
		}
	}
	
	@keyframes jiggle {
	  0%, 20% { transform: rotate(0deg) translateZ(0); }
	  2.5%, 12.5% { transform: rotate(-10deg) translateZ(0); }
	  7.5%, 17.5% { transform: rotate(10deg) translateZ(0); }
	}

}