.title {
	display: none;
	
	position: absolute;
	top:0;
	left: calc(#{$logo-width} + 20px);
	
	& a {
		font-size: 16px;
		font-family: $sans;
		font-weight: normal;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
	
	&__current {
		z-index: $title-current;
		margin: 11px 0 0 10px;
		padding: 0;
		line-height: 50px;
		position: relative;
		
		& a {
			color: #000;
			text-decoration: none;
			padding-right: 20px;
			white-space: nowrap;
			
			&::after {
				content:"";
				left: 8px;
				top: -4px;
				position: relative;
				display: inline-block;
				width: 5px;
				height: 5px;
				border: 2px solid #000;
				border-width: 0 0 2px 2px;
				transform: rotate(-45deg);
				
			}
		}
	}
	
	&__nav {
		min-width: calc(100% + 50px);
		z-index: $title-nav;
		position: absolute;
		top:0;
		left:-30px;
		margin:0;
		background-color: $yellow;
		padding: 0 30px;
		
		transition: transform .2s .1s;
		transform: translateY(-100%);
		
		.yogi & {
			background-color: $blue;
		}
	}

	.title--is-open &__nav {
		transition: transform .2s;
		transform: translateY(0%);
	}
	
	&__items {
		list-style: none;
		margin: 0;
		padding: 65px 0 20px;
		width: auto;
		
		transition: opacity .1s;
		opacity: 0;
	}
	
	.title--is-open &__items {
		transition: opacity .1s .1s;
		opacity: 1;
	}
	
	&__item {
		font-size: 18px;
		font-family: $sans;
		line-height: 50px;
		
		& a:link,
		& a:visited,
		& a:hover {
			color:#fff;
			text-decoration: none;
			padding: 0 10px;
			white-space: nowrap;
		}
		
		& a {
			display: block;
		}
		
		& a:hover {
			color: #000;
		}
	}
	
	@include when($large) {
		display: block;
	}
}