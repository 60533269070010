.header {
	width: 100%;
	margin:0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-header;
	
	&__container {
		height: $header-height;
		position: relative;
		margin: 0 auto;
	}
	
	.page--sub & {
		background-color: #fff;
	}
}