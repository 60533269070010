.instagram {
	max-width: $max-width-inner;
	margin: 0 auto;
	
	&__container {
		margin: 0 20px;
		padding:30px 0 50px 0;
		background-color: #fff;
		background-size: cover;
		position: relative;
		
		@include when($xlarge) {
			padding:80px 0 0 0;
		}
		
		.surf .instagram--has-data &::after {
			content: "";
			background-color: #fd0000;
			position: absolute;
			transform: rotate(45deg);
			mix-blend-mode: multiply;
			pointer-events: none;
			
			width: 0;
			height: 0;
			bottom: 0;
			left: 0;
			
			@include when($large) {
				width: 62%;
				height: 13px;
				bottom: 370px;
				left: 320px;
			}
			@include when($xlarge) {
				width: 62%;
				height: 13px;
				bottom: 370px;
				left: 320px;		
			}
		}
	}
	
	&__logo {
		text-align: center;
		padding-top: 30px;
		
		svg {
			width: 45px;
			height: 45px;
			
			@include when($large) {
				width: 80px;
				height: 80px;
			}
		}
	}
	
	&__title {
		padding-top: 10px;
		margin-top: 0;
		font-family: $sans;
		font-size: 26px;
		text-align: center;
		font-weight: 500;
		color: #fd0000;
		
		@include when($large) {
			font-size: 38px;			
		}
		
		& a {
			color: #fd0000;
			font-weight: bold;
		}
		
		& span {
			display: inline-block;
		}
	}
	
	&__link {
	}
	
	&__items {
		overflow: hidden;
		
		@include when($medium) {
			padding: calc( 110vw / 12.8);
			padding-left: calc(10vw / 7.68);
			padding-right: 0;
		}
		
		@include when($large) {
			padding: 0 0 120px 5.6vw;
		}
		
		@include when($xlarge) {
			padding: 0 0 120px 80px;
		}
	}
	
	&__item {
		display: block;
		margin: 20px auto 0;
		
		width: calc(100vw - 80px);
		max-width: 340px;
		height: auto;
		max-height: 340px;
		overflow: hidden;
		
		perspective: 1300px;
		
		background-color: #fff;

		mix-blend-mode: multiply;
		
		@include when($medium) {
			width: 340px;
			height: auto;
		}
		
		@include when($large) {
			float:left;
			width: calc(340vw / 13.2);
			height: calc(340vw / 13.2);
			
			margin: 0;
			
			&:nth-child(3n-2) {
				margin: calc(50vw / 13.2) calc(50vw / 14.4) 0 0;
			}
			&:nth-child(3n-1) {
				margin: calc(50vw / 13.2) 0 0 0;
			}
			&:nth-child(3n) {
				margin: calc(50vw / 13.2) 0 0 calc(50vw / 13.2);
			}
		}

		@include when($xlarge) {

			width: 340px;
			height: 340px;

			margin: 0;
			
			&:nth-child(3n-2) {
				margin: 50px 50px 0 0;
			}
			&:nth-child(3n-1) {
				margin: 50px 0 0 0;
			}
			&:nth-child(3n) {
				margin: 50px 0 0 50px;
			}
		}
		
		&:nth-child(n+4) {
			display: none;
			
			@include when($medium) {
				display: block;
			}
		}
	}
	
	&__image {
		transition: filter .3s ease;
		filter: grayscale(100%);
		
		width: 100%;
		height: auto;
		
		a:hover & {
			filter: grayscale(0%);
		}
		
		@include when($large) {
			position: relative;
			top:50%;
			transform: translateY(-50%);
		}
	}
}