.subscribe {
	max-width: $max-width-inner;
	margin: 0 auto;
	
	&__container {
		background-color: #fff;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 140px 20px;
		margin: 0 20px;
		overflow: hidden;
		
		@include when($large) {
			padding: 250px 0;
		}
	}
	
	&__title {
		font-family: $sans;
		font-size: calc((100vw - 40px) / 10);
		letter-spacing: 0.06em;
		font-weight: 500;
		text-transform: uppercase;
		color: $subscribe-white;
		text-align: center;
		margin: 0 0 20px;
		
		@include when($large) {
			font-size: calc(120vw / 12.8);
			margin: 0 0 60px;
		}
		
		@include when($xlarge) {
			font-size: 120px;
			margin: 0 0 60px;
		}
	}
	
	&__forms {
		position: relative;
	}
	
	&__forms form {
		
		transition: left 200ms;
		position: relative;
		left:0px;
	}
	
	.--has-submitted form,
	.--show-message form {
		left: -100%;
	}
	
	&__row {
		overflow: hidden;
		width: 260px;
		max-width: 98%;
		margin: 0 auto;
		
		@include when($large) {
			width: 430px;
		}
	}
	
	&__email {
		float:left;
		font-family: $sans;
		font-weight: bold;
		border:3px solid $subscribe-white;
		background: transparent;
		color: $subscribe-white;
		border-radius: 0;

		font-size: 16px;
		width: 57%;
		height: 45px;
		line-height: 45px;
		text-indent: 10px;
		
		@include when($large) {
			font-size: 25px;
			height: 72px;
			line-height: 70px;
			text-indent: 30px;
		}
	}
	
	&__submit {
		float:left;
		background-color: $subscribe-white;
		border: 0;
		text-transform: uppercase;
		font-weight: bold;
		font-family: $sans;
		color: #3d1e38;
		white-space: nowrap;
		padding: 0px 0px 0px 0px;

		font-size: 16px;
		width: 43%;
		height: 45px;
		line-height: 45px;
		
		@include when($large) {
			font-size: 23px;
			height: 72px;
			line-height: 70px;
		}
	}
	
	&__message {
		position: absolute;
		width: 100%;
		top:0;
		left: 100%;
		transition: left 200ms;
		text-align: center;
		color: $subscribe-white;
		font-weight: bold;
		font-size: 16px;
		font-family: $sans;
		line-height: 45px;
		
		@include when($large) {
			font-size: 25px;
			line-height: 70px;
		}
		
		&--is-visible {
			left:0;
		}
		
		& a:link,
		& a:hover,
		& a:visited {
			color: $subscribe-white;
		}
	}
	
	& ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		color: $subscribe-white;
	}
	& :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		color: $subscribe-white;
		opacity: 1;
	}
	& ::-moz-placeholder { /* Mozilla Firefox 19+ */
		color: $subscribe-white;
		opacity: 1;
	}
	& :-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: $subscribe-white;
	}
	& ::-ms-input-placeholder { /* Microsoft Edge */
		color: $subscribe-white;
	}
	
	& ::placeholder { /* Most modern browsers support this now. */
		color: $subscribe-white;
	}
	
}