.facts {
	max-width: 980px;
	margin: 150px auto;
	padding: 5.1vw;
	background-image: image('truefacts-bg.png');
	background-size: cover;
	
	@include when('screen and (min-width: 980px)') {
		padding: 50px;
	}
	
	&__container {
		padding: 10.2vw 15.3vw;
		border: 2px solid #034dd5;
		position: relative;
		
		@include when('screen and (min-width: 980px)') {
			padding: 110px 17% 100px;
		}
	}
	&__image {
		position: absolute;
		width: 95%;
		top: -11.22vw;
		right: 0;
		
		& img {
			width: 100%;
			height: auto;
		}
		
		@include when('screen and (min-width: 980px)') {
			top: -110px;
		}
	}
	&__title {
		mix-blend-mode: difference;
		color: #fdb114;
		font-size: 20.41vw;
		font-weight: 500;
		line-height: 0.8;
		text-align: center;
		font-family: $sans;
		background-position: right top;
		background-size: 95% auto;
		margin: 0;
		
		@include when('screen and (min-width: 980px)') {
			font-size: 200px;
		}
	}
	& p {
		max-width: 580px;
		margin: 50px auto;
		font-size: 18px;
		text-align: center;
		line-height: 30px;
		color: #fdb114;
		
		& strong {
			font-weight: normal;
			color: #034ed6;
		}
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}