.ie11 {
	.page .content__container > div.video {
		margin-top: 80px;
	}
	
	.sidebar__toggle,
	.sidebar-closer,
	.booking {
		display: none;
	}
	
	.page--no-scroll {
		.sidebar__toggle,
		.sidebar-closer,
		.booking {
			display: block;
		}	
	}
	
	.booking__container {
		.sidebar-back {
			left: calc(100vw - 460px);
		}
		
		.bw-sidebar-button__full-width {
			width: 460px;
			left: auto;
			right: 0;
		}
	}
	
	.text__quote {
		&::before {
			content: none;
		}
	}
	&.surf .text__quote {
		color: $yellow2;
	}
	&.yogi .text__quote {
		color: $blue;
	}
}