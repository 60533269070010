.destinations {
	
	max-width: $max-width-inner;
	margin: 0 auto;
	
	&__container {
		background-size: cover;
		
		margin: 0 20px;
		
		padding-bottom: 20px;
		
		@include when($xlarge) {
			padding-bottom: 0;
		}
	}
	
	&__title {
		display: none;
	}

	&__items {
		list-style: none;
		padding: 0;
		margin: 0;
		overflow: hidden;
		
		@include when($medium) {
			padding: calc( 110vw / 12.8);
			padding-left: calc(10vw / 7.68);
			padding-right: 0;
		}
		
		@include when($large) {
			padding: 70px 0 120px 5.6vw;
		}
		
		@include when($xlarge) {
			padding: 70px 0 120px 80px;
		}
	}
	
	&__item {
		margin: 20px auto 0;
		
		width: calc(100vw - 80px);
		max-width: 340px;
		height: calc((100vw - 80px) / 0.75);
		max-height: 454px;
		
		perspective: 1300px;
		
		background-color: #fff;
		
		position: relative;

		@include when($medium) {
			width: 340px;
			height: 454px;
		}
		
		@include when($large) {
			float:left;
			width: calc(340vw / 13.2);
			height: calc(454vw / 13.2);
			
			margin: 0;
			
			&:nth-child(3n-2) {
				margin: calc(50vw / 13.2) calc(50vw / 14.4) 0 0;
			}
			&:nth-child(3n-1) {
				margin: calc(50vw / 13.2) 0 0 0;
			}
			&:nth-child(3n) {
				margin: calc(50vw / 13.2) 0 0 calc(50vw / 13.2);
			}
		}

		@include when($xlarge) {

			width: 340px;
			height: 454px;

			margin: 0;
			
			&:nth-child(3n-2) {
				margin: 50px 50px 0 0;
			}
			&:nth-child(3n-1) {
				margin: 50px 0 0 0;
			}
			&:nth-child(3n) {
				margin: 50px 0 0 50px;
			}
		}
	}
	
	&__link {
		position: relative;
		color: #fff;
		text-align: center;
		text-decoration: none;
		display: block;
		height: 100%;
		
		background-size: cover;
		background-size: 0px;
		
		box-shadow: 0 8px 16px rgba(0,0,0,.25), 0 4px 8px rgba(0,0,0,.25), 0 2px 4px rgba(0,0,0,.25);
	}
	
	&__link:hover {
	}
	
	&__info {
		position: relative;
		height: 100%;
		z-index: $z-index-destinations-info;
		background-image: linear-gradient(rgba(0,0,0,0) 65%, rgba(0,0,0,0.5) 100%);
	}
	
	&__period {
		font-family: $sans;
		text-transform: uppercase;
		line-height: 45px;
		font-size: 11px;
		letter-spacing: 2px;
		font-weight: 600;
	}
	
	&__location {
		font-family: $serif;
		font-size: 10vw;
		position: absolute;
		left: 0;
		bottom: 45px;
		width: 100%;
		padding: 0 5px;
		
		@include when($medium) {
			font-size: 44px;
		}
		
		@include when($large) {
			font-size: calc(44vw/13.2);
		}
		
		@include when($xlarge) {
			font-size: 44px;
		}
	}
	
	&__desc {
		font-family: $sans;
		text-transform: uppercase;
		line-height: 45px;
		font-size: 11px;
		letter-spacing: 2px;
		font-weight: 600;

		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
	}
	
	&__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		z-index: $z-index-destinations-video;
		
		& video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}