.logo {
	width: $logo-width;
	height: $logo-height;
	overflow: hidden;
	text-indent: -100vw;
	margin: 10px 0 0 10px;
	
	position: absolute;
	left: 0;
	top: 0;
	
	background-image: image('logo.png');
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	
	.page--front & {
		background-image: image('logo.svg');
	}
	
	.yogi .page--front &,
	.yogi .page--sub & {
		background-image: image('logo_yogi.png');
	}
	
	& h1,
	& a {
		display: block;
		height: 100%;
		margin: 0;
		padding: 0;
		color: rgba(0,0,0,0);
		font-size: 1px;
	}
}