.nav {
	display: none;
	
	margin: 0 auto;
	text-align: center;
	
	width: calc(100vw - 160px);
	max-width: 1280px;
	
	&__items {
		list-style: none;
		padding: 0;
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		height: $header-height;
	}
	
	&__item {
		display: inline-block;
		margin: 15px 10px;
		line-height: 50px;
		
		font-family: $sans;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: normal;
		letter-spacing: 1px;
	}

	& a {
		display: block;
		padding: 0 10px;
	}
	
	& a:link,
	& a:visited,
	& a:hover {
		color: $header-color;
		text-decoration: none;
	}
	
	.page--sub & a:link,
	.page--sub & a:visited,
	.page--sub & a:hover {
		color: $header-color-dark;
	}

	
	@include when($xlarge) {
		display: block;
	}
}