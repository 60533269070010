.booking {
	
	&--is-open {
	}
	
	&__overlay {
		background-color: rgba(0,0,0,0);
		pointer-events: none;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: fixed;
		z-index: $z-index-sidebar-overlay;
		transition: background-color .2s;
	}
	
	&--is-open &__overlay {
		background-color: rgba(0,0,0,.6);
		pointer-events: auto;
	}
	
	&__container {
		position: absolute;
		width: 460px;
		max-width: 100%;
		height: 100vh;
		right: -460px;
		top: 0;
		background-color: #fff;
		box-shadow: 0 0 80px rgba(0,0,0,0);
		
		transition: right .3s, box-shadow .3s;
		z-index: $z-index-sidebar-container;
		
		transform: translate3d(0,0,0);
		overflow: hidden;
		z-index: 1051;
		pointer-events: none;
	}
	
	&--is-open &__container {
		right: 0;
		pointer-events: auto;
		box-shadow: 0 0 80px rgba(0,0,0,.5);
	}
	
}

//Legacy environment

.booking__container {
	
	line-height: 1.428571429;
	font-family: $sans;
	
	h2 {
		margin: 150px 0 90px;
		font-size: 24px;
		line-height: 40px;
		letter-spacing: 7px;
		color: #000;
	}
	
	h2,
	h3,
	h4 {
		font-family: $sans;
		text-transform: uppercase;
		color: #000;
		font-weight: 700;
	}
	
	h2 small,
	h3 small,
	h4 small {
		color: #666;
	}
	
	.material-icons {
		vertical-align: top;
	}
	
	a {
		text-decoration: none;
	}
	p {
		margin: 0;
		line-height: 1.6;
	}
	.row {
	}
	.row::before, .row::after {
		content: " ";
		display: table;
		clear: both;
	}
	.row.no-gutter {
		margin-right: 0;
		margin-left: 0;
	}
	.row.no-gutter > [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
	.col-xs-3 {
		width: 25%;
	}
	.col-xs-4 {
		width: 33.33333333333333%;
	}
	.col-xs-5 {
		width: 41.66666666666667%;
	}
	.col-xs-6 {
		width: 50%;
	}
	.col-xs-7 {
		width: 58.333333333333336%;
	}
	.col-xs-8 {
		width: 66.66666666666666%;
	}
	.col-xs-9 {
		width: 75%;
	}
	.col-xs-12 {
		width: 100%;
	}
	.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
		float: left;
	}
	.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
		position: relative;
		min-height: 1px;
		padding-left: 30px;
		padding-right: 30px;
	}
	.text-right {
		text-align: right;
	}
}

//Legacy styles


.booking__container {

	@media only screen and (max-width: 640px) {
		select:focus,
		textarea:focus,
		input:focus {
			font-size: 20px !important;
			line-height: 24px !important;
		}
	}

	@media only screen and (max-width: 640px) {
		select,
		textarea,
		input {
			font-size: 20px !important;
			line-height: 24px !important;
		}
	}
	/*#region Navigation*/
	a.sidebar-back:hover,
	a.sidebar-back:focus,
	a.sidebar-closer:hover,
	a.sidebar-closer:focus {
		color: white !important;
		text-decoration: none !important;
	}

	.sidebar-closer, .sidebar-back {
		position: fixed;
		top: 0;
		z-index: 5000;
		color: #fff;
		font-size: 12px;
		font-weight: bold;
		line-height: 24px;
		letter-spacing: 2px;
		text-transform: uppercase;
		padding: 20px;
		transform: translateZ(0);
	}

	.sidebar-closer:hover, .sidebar-back:hover {
		background: rgba(0,0,0,0.5);
	}

	.sidebar-closer {
		text-shadow: 0 1px 2px rgba(0,0,0,0.5);
		right: 0;
		top: 0;
	}

	.sidebar-closer__gray {
		text-shadow: none;
		color: #C6C6C6;
	}

	.sidebar-closer__gray:hover {
		color: #000 !important;
	}

	.sidebar-back {
		left: 0;
		text-shadow: 0 1px 2px rgba(0,0,0,0.5);
	}

	.sidebar-back span {
		vertical-align: middle;
	}
	/*#endregion*/
	/*#region Button*/
	.bw-sidebar-button {
		transition: all 0.2s ease-out;
		border-radius: 30px;
		background: #fdb114;
		color: #fff;
		font-size: 14px;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		position: relative;
		box-shadow: inset 0 0 0 0 transparent, inset 0 0 0 0 transparent !important;
		padding: 9px 13px;
		display: inline-block;
		vertical-align: middle;
	}

	.bw-sidebar-button:focus {
		background: black;
	}

	.bw-sidebar-button__icon-right {
		padding-right: 30px;
	}

	.bw-sidebar-button__icon-right .material-icons {
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);
	}

	.bw-sidebar-button__big {
		padding: 14px 24px;
		letter-spacing: 2px;
	}

	.bw-sidebar-button__rounded {
		border-radius: 100px;
	}

	.bw-sidebar-button__full-width {
		display: block;
		border-radius: 0;
		font-size: 20px;
		font-family: $sans;
		letter-spacing: 10px;
		padding: 30px 0;
		width: 100%;
	}

	.bw-sidebar-button__bottom {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 5000;
		transform: translateZ(0);
	}
	/*#endregion*/
	/*#region Spinner*/
	.bw-spinner {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.bw-spinner-item {
		display: inline-block;
		width: 40px;
		height: 40px;
		margin: 0;
		vertical-align: middle;
	}

	.bw-spinner-item:nth-child(2) {
		margin: 0 6px;
	}

	.bw-spinner-button {
		cursor: default;
		background: #F9F9F9;
		color: #ccc;
		border: 2px solid #ccc;
		border-radius: 4px;
		outline: none;
		width: 100%;
		height: 100%;
		display: inline-block;
		position: relative;
	}

	.bw-spinner-button:hover {
		color: #ccc;
	}

	.bw-spinner-button:focus {
		color: #ccc !important;
	}

	.bw-spinner-button__highlighted {
		cursor: pointer;
		background: #fff;
		border-color: #E7B427;
		color: #E7B427;
	}

	.bw-spinner-button__highlighted:hover {
		background: #fdb114;
		border-color: #fdb114;
		color: #fff;
	}

	.bw-spinner-button__highlighted:active {
		transform: scale(0.9);
		color: #fff;
	}

	.bw-spinner-button__highlighted:focus {
		color: #fff !important;
		background: #fdb114;
	}

	.bw-spinner-button i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.bw-spinner-input {
		transition: all 0.2s ease-out;
		background-color: #E5E5E5;
		color: #000;
		border: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		vertical-align: middle;
	}

	.bw-spinner-input:focus {
		outline: 0;
		border: 1px solid #fdb114;
		background: white;
		box-shadow: 0 0 8px rgba(253,177,20,0.5);
	}
	/*#endregion*/
	/*#region Checkbox */
	.bw-checkbox {
		border-radius: 100px;
		background: #E0DBD9;
		width: 80px;
		height: 40px;
		padding: 3px;
		position: relative;
		display: inline-block;
		cursor: pointer;
		margin-bottom: -5px; /* RIP Magic margin... */
		transition: background ease-out 0.2s;
	}

	.bw-checkbox-indicator {
		display: block;
		background: #fff;
		border-radius: 100px;
		width: 34px;
		height: 34px;
		position: relative;
		left: 0;
		pointer-events: none;
		margin: 0;
		transition: left cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
	}

	.bw-checkbox__checked {
		background: #fdb114;
	}

	.bw-checkbox__checked .bw-checkbox-indicator {
		left: calc(100% - 34px);
	}

	.bw-checkbox-input {
		display: none;
	}
	/*#endregion*/
	/*#region Loader*/
	@-webkit-keyframes rotating {
		from {
			-webkit-transform: rotate(0deg);
		}

		to {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes rotating {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	.loader {
		-webkit-animation: rotating 1s linear infinite;
		animation: rotating 1s linear infinite;
		display: none;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 44px;
		height: 44px;
		margin-left: -22px;
		margin-top: -22px;
		z-index: 100;
	}

	.bw-sidebar__show .sidebar--loading .bw-sidebar-page {
		pointer-events: none;
	}

	.sidebar--loading .loader {
		display: block;
	}
	/*#endregion*/

	.bw-sidebar {
		background-color: #fff;
		box-shadow: 0 0 80px rgba(0,0,0,0.5);
		position: absolute;
		right: -460px;
		top: 0;
		width: 460px;
		height: 100%;
		transform: translate3d(0,0,0);
		overflow: hidden;
		z-index: 1051;
		pointer-events: none;
		display: none;
	}

	.bw-sidebar__show .bw-sidebar {
		pointer-events: all;
		display: block;
	}

	.bw-sidebar__show, .bw-sidebar__show body {
		overflow: hidden;
	}

	.bw-sidebar-overlay {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: rgba(0,0,0,0.6);
		z-index: 1050;
		visibility: hidden;
		pointer-events: none;
		opacity: 0;
		transition: all ease-in-out 500ms;
	}

	.bw-sidebar__show .bw-sidebar-overlay {
		visibility: visible;
		pointer-events: all;
		opacity: 1;
	}

	.bw-sidebar-page {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
		z-index: 1;
		text-align: center;
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		opacity: 0;
	}

	.bw-sidebar-current-page {
		visibility: visible;
		z-index: 1;
		opacity: 1;
	}

	.bw-sidebar-background {
		display: inline-block;
		width: 100%;
		min-height: 100vh;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center top;
	}

	.bw-sidebar h2,
	.booking__container h2 {
		margin: 150px 0 90px;
		font-size: 34px;
		line-height: 40px;
		letter-spacing: 7px;
		color: #000;
	}

	.bw-sidebar h2 small,
	.booking__container h2 small {
		font-size: 14px;
		color: #666;
	}
	/*#region Sidebar change effect*/
	@keyframes defaultPageEffectIn {
		from {
			left: 100%;
		}

		to {
			opacity: 1;
			left: 0;
		}
	}

	@keyframes defaultPageEffectOut {
		from {
			left: 0;
		}

		to {
			left: -100%;
		}
	}

	@keyframes destinationPageEffectIn {
		from {
			left: 100%;
		}

		to {
			opacity: 1;
			left: 0;
		}
	}

	@keyframes destinationPageEffectOut {
		from {
			left: 0;
		}

		to {
			left: -100%;
		}
	}

	@keyframes destinationPageEffectInBack {
		from {
			left: -100%;
		}

		to {
			opacity: 1;
			left: 0;
		}
	}

	@keyframes destinationPageEffectOutBack {
		from {
			left: 0;
		}

		to {
			left: 100%;
		}
	}

	.bw-sidebar-page-effect-in {
		animation: defaultPageEffectIn .4s cubic-bezier(0.075, 0.82, 0.165, 1) backwards;
		visibility: visible;
		pointer-events: none;
	}

	.bw-sidebar-page-effect-out {
		animation: defaultPageEffectOut .4s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
		visibility: visible;
		pointer-events: none;
	}

	.bw-sidebar-page-effect-in.bw-sidebar-page-effect-back {
		animation-name: destinationPageEffectInBack;
	}

	.bw-sidebar-page-effect-out.bw-sidebar-page-effect-back {
		animation-name: destinationPageEffectOutBack;
	}
	/*#endregion*/
	/*#region Destinations */
	.bw-sidebar-destinations h2 {
		margin: 140px 0 130px;
	}

	.bw-sidebar-destinations-list {
		padding: 0 20px;
	}

	.bw-sidebar-destinations-item {
		margin-bottom: 2px;
		border-radius: 5px;
		position: relative;
		min-height: 140px;
		display: block;
		text-transform: uppercase;
		color: #fff;
		background-position: 0 25%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.bw-sidebar-destinations-item:hover {
		color: #fff !important;
		transform: scale(.95);
		z-index: 1;
	}

	.bw-sidebar-destinations-item:focus {
		color: #fff !important;
		transform: scale(.9);
		z-index: 1;
	}

	.bw-sidebar-destinations-item h3 {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		font-family: $sans;
		font-size: 20px;
		line-height: 22px;
		letter-spacing: 6px;
		color: #fff;
		margin: 0;
	}

	.bw-sidebar-destinations-item p {
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;
		font-size: 12px;
		font-weight: bold;
		line-height: 20px;
		letter-spacing: 3px;
		margin: 0 !important;
	}

	.bw-sidebar-destinations-item__disabled {
		filter: grayscale(100%);
		pointer-events: none;
	}
	/*#endregion*/
	/*#region Packages*/
	.bw-sidebar-packages h2 {
		color: #fff;
	}

	.bw-sidebar-packages__multiple .bw-sidebar-packages-list {
		padding-bottom: 60px;
	}

	.bw-sidebar-packages .bw-sidebar-button__big {
		margin-bottom: 20px;
		box-shadow: 0 4px 16px rgba(0,0,0,0.25);
	}

	.bw-sidebar-packages-list {
		margin-bottom: 60px;
		padding: 0 20px;
	}

	.bw-sidebar-packages-year {
		background-color: rgba(0, 0, 0, 0.50);
		border-radius: 5px;
		color: #fff;
		display: inline-block;
		font-weight: bolder;
		height: 34px;
		letter-spacing: 3px;
		line-height: 34px;
		margin: 30px auto 10px;
		padding: 0 15px;
	}

	.bw-sidebar-packages-item {
		transition: all 0.1s ease-out;
		text-align: left;
		background: #fff;
		border-radius: 4px;
		box-shadow: inset 0 0 0 0 #fdb114, 0 0 0 0 white, 0 2px 4px 0 rgba(0,0,0,0.25);
		position: relative;
		margin-bottom: 2px;
		padding: 24px 20px;
	}

	.bw-sidebar-packages__almost-full a, .bw-sidebar-packages__open a, .bw-sidebar-packages-date span strong, .bw-sidebar-packages-status, .bw-sidebar-button {
		transition: all 0.1s ease-out !important;
	}

	.bw-sidebar-packages__almost-full:hover, .bw-sidebar-packages__open:hover {
		cursor: pointer;
		z-index: 1;
		box-shadow: inset 0 0 0 2px #fdb114, 0 0 0 4px white, 0 1px 4px 5px rgba(0,0,0,0.5);
	}

	.bw-sidebar-packages__almost-full:hover > .bw-sidebar-packages-date, .bw-sidebar-packages__open:hover > .bw-sidebar-packages-date strong, .bw-sidebar-packages__open:hover > .bw-sidebar-packages-date, .bw-sidebar-packages__open:hover > .bw-sidebar-packages-date strong {
		color: #fdb114 !important;
	}

	a.sidebar-opener.bw-sidebar-button.bw-sidebar-button__rounded.bw-sidebar-button__big {
		letter-spacing: 0;
	}

	a.sidebar-opener.bw-sidebar-button.bw-sidebar-button__rounded.bw-sidebar-button__big:hover {
		box-shadow: 0 0 0 4px black !important;
		background: black;
	}

	a.sidebar-opener.bw-sidebar-button.bw-sidebar-button__rounded.bw-sidebar-button__big:active, a.sidebar-opener.bw-sidebar-button.bw-sidebar-button__rounded.bw-sidebar-button__big:focus {
		box-shadow: 0 0 0 4px black !important;
		background: black;
	}

	.bw-sidebar-button {
		box-shadow: 0 0 0 0 white, 0 0 0 0 #fdb114 !important;
	}

	.bw-sidebar-packages__almost-full:hover > .bw-sidebar-button, .bw-sidebar-packages__open:hover > .bw-sidebar-button {
		box-shadow: 0 0 0 2px white, 0 0 0 4px #fdb114 !important;
	}

	.bw-sidebar-packages-date {
		font-size: 16px;
		color: #aaa;
	}

	.bw-sidebar-packages-date span {
		transition: all 0.1s ease-out;
		vertical-align: bottom;
	}

	.bw-sidebar-packages-date strong {
		color: #000;
	}

	.bw-sidebar-packages-status {
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 1px;
		color: #fdb114;
		text-transform: uppercase;
	}

	.bw-sidebar-packages-date .material-icons {
		margin: 0 -5px;
	}

	.bw-sidebar-packages-item .bw-sidebar-button, .bw-sidebar-packages-item .bw-checkbox {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}

	.bw-sidebar-packages__full {
		background: rgba(255,255,255,0.75);
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(8px);
	}

	.bw-sidebar-packages__almost-full {
		background: rgba(255,255,255,0.75);
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(8px);
	}

	.bw-sidebar-packages__full .bw-sidebar-packages-date, .bw-sidebar-packages__full .bw-sidebar-packages-status {
		color: rgba(0,0,0,0.3);
	}

	.bw-sidebar-packages__full .bw-sidebar-button {
		opacity: 0.5;
		color: #fff;
		background: #000;
	}

	.bw-sidebar-packages__almost-full .bw-sidebar-packages-status {
		color: #BF2808;
	}

	.bw-sidebar-packages-item .bw-sidebar-button__icon-right {
		right: 10px;
		padding-right: 25px;
	}

	.bw-sidebar-packages-item .bw-sidebar-button__icon-right .material-icons {
		position: absolute;
		right: 3px;
		top: 50%;
		transform: translateY(-50%);
	}
	/*#endregion*/
	/*#region Form*/
	.bw-sidebar-form .bw-sidebar-packages-list {
		padding-bottom: 110px;
	}

	.bw-sidebar-form h2 {
		color: #fff;
		margin-bottom: 160px;
	}

	.bw-sidebar-form h3, .bw-sidebar-form h4 {
		font-size: 16px;
		line-height: 40px;
		text-transform: none;
		font-family: $sans;
		margin: 0;
		letter-spacing: normal;
		vertical-align: middle;
		font-weight: 500;
	}

	.bw-sidebar-form-list {
		padding: 0 20px;
	}

	.bw-sidebar-form-line {
		text-align: left;
		background: #fff;
		border-radius: 4px;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
		position: relative;
		margin-bottom: 2px;
		padding: 20px;
	}

	.bw-sidebar-waiting-list {
		background: url(/images/assets/hangten.png) no-repeat 20px 50% #fcf7e7;
		background-size: 47px 52px;
		color: #907932;
		font-weight: bold;
		font-size: 14px;
		padding-left: 80px;
	}

	.bw-sidebar-line-spacer {
		height: 100px;
	}

	.bw-sidebar-form-details {
		padding: 10px 20px;
	}

	.bw-sidebar-form-details .col-xs-6:first-child {
		padding-right: 10px;
	}

	.bw-sidebar-form-details .col-xs-6:last-child {
		padding-left: 10px;
	}

	.bw-sidebar-form-address {
		display: none;
		padding: 10px 20px;
	}

	.bw-sidebar-form-address .col-xs-4:first-child {
		padding-right: 10px;
	}

	.bw-sidebar-form-address .col-xs-8:last-child {
		padding-left: 10px;
	}

	.bw-sidebar-extra-days .row {
		margin-bottom: 20px;
	}

	.bw-sidebar-extra-days .row:last-child {
		margin-bottom: 0;
	}

	.bw-sidebar-extra-item img {
		padding-right: 15px;
		max-width: 100%;
	}

	.bw-sidebar-extra-item h4 {
		color: #000 !important;
		margin: 0 0 5px !important;
		line-height: 26px;
	}

	.bw-sidebar-extra-item p {
		color: #f5f5f5;
		font-size: 12px;
	}

	.bw-sidebar-extra-item strong {
		color: #000;
	}

	.bw-sidebar-extra-item a {
		color: #fdb114;
		text-decoration: underline;
	}

	.bw-sidebar-extra-item a.bw-spinner-button {
		color: #ccc;
	}

	.bw-sidebar-extra-item a.bw-spinner-increment {
		color: #fdb114;
	}

	.bw-sidebar-form-input {
		transition: all 0.2s ease-out;
		background: #f5f5f5;
		border: 1px solid #ccc;
		width: 100%;
		margin: 8px 0;
		padding: 12px;
		font-size: 16px;
		line-height: 18px;
		border-radius: 4px;
		box-shadow: 0 0 0 0 white, 0 0 0 0 #fdb114, 0 0 0 0 rgba(253,177,20,0.5),inset 0 1px 2px rgba(0,0,0,0.1);
	}

	.bw-sidebar-form-input:focus {
		position: relative;
		outline: 0;
		border: 1px solid white;
		font-size: 16px;
		background: white;
		color: black;
		box-shadow: 0 0 0 3px white, 0 0 0 4px #fdb114, 0 0 5px 5px rgba(253,177,20,0.5), inset 0 0 0 0 rgba(0,0,0,0);
	}

	textarea.bw-sidebar-comment {
		height: 100px;
		border: 1px solid #ccc;
		transition: all 0.2s ease-out;
		background: #f5f5f5;
		width: 100%;
		margin: 8px 0;
		padding: 12px;
		font-size: 16px;
		line-height: 24px;
		border-radius: 4px;
		box-shadow: 0 0 0 0 white, 0 0 0 0 #fdb114, 0 0 0 0 rgba(253,177,20,0.5),inset 0 1px 2px rgba(0,0,0,0.1);
	}

	textarea.bw-sidebar-comment:focus {
		position: relative;
		outline: 0;
		border: 1px solid transparent;
		background: white;
		font-size: 16px;
		color: black;
		box-shadow: 0 0 0 3px white, 0 0 0 4px #fdb114, 0 0 5px 5px rgba(253,177,20,0.5), inset 0 0 0 rgba(0,0,0,0);
	}

	.bw-sidebar-form-line p:last-child {
		margin-bottom: 0;
	}

	.bw-sidebar-totals {
		color: #fff;
		text-align: left;
		margin: 40px 0;
		padding: 0px 40px;
	}

	.bw-sidebar-totals-line {
		border-bottom: 1px dotted rgba(255,255,255,0.2);
		font-size: 14px;
		line-height: 31px;
	}

	.bw-sidebar-totals-line__bold {
		border-bottom: 2px solid rgba(255,255,255,1);
		font-weight: bold;
		line-height: 35px;
	}

	.bw-sidebar-totals-line__large {
		border-bottom-width: 1px;
		font-weight: bold;
		font-size: 18px;
		line-height: 53px;
		margin-bottom: 11px;
	}

	.bw-sidebar-totals-line__centered {
		border-bottom: 0;
		text-align: center;
		font-style: italic;
		line-height: 20px;
	}

	.bw-sidebar-totals-line__left {
		border-bottom: 0;
		font-style: italic;
		font-size: 14px;
		line-height: 31px;
	}

	.bw-sidebar-totals-line p:last-child {
		margin-bottom: 0;
	}

	.bw-sidebar-form-order {
		width: 100%;
	}

	.bw-sidebar-payment-information {
		color: #fff;
		padding: 20px 40px;
		text-align: left;
		font-size: 12px;
		line-height: 1.67;
		background-color: rgba(0,0,0,.25);
	}

	.bw-sidebar-payment-information a {
		color: #fff;
	}

	.bw-sidebar-payment-information img {
		width: auto;
	}

	.bw-sidebar-payment-terms {
		color: #fff;
		background-color: rgba(0,0,0,.5);
		padding: 30px 40px;
		font-size: 18px;
		line-height: 1.22;
		padding-bottom: 120px;
		text-align: left;
	}

	.bw-sidebar-payment-terms-row {
		position: relative;
	}

	.bw-custom_checkbox {
		position: absolute;
		top: 50%;
		transform: translateY(-15px);
	}

	.bw-sidebar-payment-terms input {
		width: 30px;
		height: 30px;
		position: absolute;
		top: 0;
		left: 0;
		cursor: pointer;
		opacity: 0;
	}

	.bw-sidebar-payment-terms .material-icons {
		position: absolute;
		top: 0;
		left: 0;
		font-size: 24px;
		display: inline-block;
		width: 30px;
		height: 30px;
		line-height: 26px;
		border-radius: 4px;
		background-color: transparent;
		color: rgba(0,0,0,0);
		text-align: center;
		pointer-events: none;
		box-sizing: border-box;
		border: 2px solid #fdb114;
		transition: background-color .2s .2s, color .2s;
	}

	.bw-sidebar-payment-terms :checked ~ .material-icons {
		background-color: #fdb114;
		color: #fff;
		transition: color .2s .2s, background-color .2s;
	}

	.bw-sidebar-payment-terms :active ~ .material-icons {
		background-color: rgba(253, 176, 20, 0.26);
	}

	.bw-sidebar-payment-terms label {
		padding-left: 40px;
		display: block;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		cursor: pointer;
	}

	.bw-sidebar-payment-terms a {
		color: #fff;
		font-weight: bold;
		text-decoration: underline;
	}
	/*#endregion*/

	@media only screen and (max-device-width : 1024px) {
		.bw-sidebar {
			width: auto;
			height: auto;
			overflow: visible;
			box-shadow: none;
		}

		.bw-sidebar__show .bw-sidebar {
			transform: none;
		}

		.bw-sidebar-page {
			//			overflow: visible;
			display: none;
		}

		.bw-sidebar-current-page {
			//			position: relative;
			display: block;
		}

		.bw-sidebar-page-effect-out, .bw-sidebar-page-effect-in {
			display: block;
		}

		.bw-sidebar__show .bw-sidebar {
			position: static;
		}

		.bw-sidebar__show, .bw-sidebar__show body {
			padding: 0;
			overflow: visible;
			position: static;
			width: auto;
			height: auto;
		}

		.bw-sidebar__show .website, .bw-sidebar__show .bw-sidebar-overlay {
			display: none;
		}

		.bw-sidebar__show footer.visible-sm.visible-xs {
			display: none !important;
		}
	}
}

/* smaller subtitle for packages on mobile */
@media screen and (max-width:430px) {
	.booking__container .bw-sidebar-packages-status {
		font-size: 9px;
	}

	.booking__container .bw-sidebar-packages-item .bw-sidebar-button__icon-right {
		padding-right: 13px;
	}

	.booking__container .bw-sidebar-packages-item .bw-sidebar-button__icon-right .material-icons {
		display: none;
	}
}

/* wrap to new row with long package descriptions */
.booking__container .bw-sidebar-packages-status {
	max-width: calc(100% - 105px);
	display: inline-block;
}

/* fix for Betala-button not under toolbar in mobile safari */
@media screen and (max-width:430px) {
	.booking__container .bw-sidebar-payment-terms {
		padding-bottom: 40px;
	}

	.booking__container .bw-sidebar-button__bottom {
		position: static;
		margin-bottom: 114px;
	}
}