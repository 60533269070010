$small : 'only screen and (max-width: 479px)';
$small-only : 'only screen and (max-width: 479px) and (max-width: 479px)';
$medium: 'only screen and (min-width: 480px)';
$medium-only: 'only screen and (min-width: 480px) and (max-width: 767px)';
$large: 'only screen and (min-width: 768px)';
$large-only: 'only screen and (min-width: 768px) and (max-width: 1319px)';
$large-vw: 7.68;
$xlarge: 'only screen and (min-width: 1320px)';
$xlarge-vw: 12.8;

@mixin when($media) {
	@media #{$media} {
		@content;
	}
}