.footernav {
	width: 100%;
	text-align: right;
	font-size: 26px;
	font-family: $sans;
	color: $footer-color;
	& a {
		color: $footer-color;
	}
	
	@include when($medium) {
		font-size: 28px;
	}
	
	@include when($large) {
		float: right;
		width: 50%;
	}
	
	&__title {
		
	}
	
	&__items {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	
	&__item, & p {
		margin-top: $footer-row-margin;
		
		&:first-child {
			margin-top: 0;
		}
	}
}