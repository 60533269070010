.blog {
	max-width: $max-width-inner;
	margin: 0 auto;
	
	&__container {
		margin: 0 20px;
		padding: 150px 0 150px 0;
		overflow: hidden;
	}
	
	&__image {
		width: 100%;
		
		@include when($large) {
			float:left;
			width: 50%;
		}
		
		img {
			display: block;
			width: 100%;
			height: auto;
			max-height: 500px;
			object-fit: cover;
			overflow: hidden;
		}
	}
	
	&__info {
		width: 100%;
		max-width: 325px;
		margin: 0 auto;
		
		@include when($large) {
			max-width: 100%;
			width: 50%;
			padding: 0 40px;
		}
	}
	&__image + .blog__info {
		
		@include when($large) {
			float:left;
			padding: 0 0 0 80px;
		}
	}
	
	&__name {
		font-family: $sans;
		line-height: 30px;
		color: #fff;
		
		font-size: 20px;
		line-height: 1;
		margin: 30px 0;
		
		@include when($large) {
			font-size: calc(30vw / 14.4);
			margin: 30px 0;
		}
		
		@include when($xlarge) {
			font-size: 30px;
			margin: 30px 0;
		}
	}
	
	&__title {
		font-family: $serif;
		line-height: 45px;
		color: #fff;
		
		font-size: 38px;
		line-height: 45px;
		margin: 0 0 70px;
		
		@include when($large) {
			font-size: calc(60vw / 14.4);
			margin: 0 0 calc(70vw / 14.4);
			line-height: 1;
		}
		
		@include when($xlarge) {
			font-size: 60px;
			margin: 0 0 70px;
		}
		
		&::before {
			content:"";
			width: 48px;
			height: 3px;
			background-color: #fff;
			display: block;
			float: left;
			margin-top: .5em;
			margin-right: 20px;
		}
	}
	
	&__link {
		color: #fcb931;
		font-family: $serif;
		font-size: 20px;
		
		.material-icons {
			vertical-align: bottom;
			font-size: 20px;
		}
		
		@include when($large) {
			font-size: calc(34vw / 14.4);
			
			.material-icons {
				font-size: calc(34vw / 14.4);
			}
		}
		
		@include when($xlarge) {
			font-size: 34px;
			
			.material-icons {
				font-size: 34px;
			}
		}
	}
}