.food {
    max-width: $max-width-inner;
    margin: 0 auto;

    &__container {
        margin: 0 20px;
        padding: 0;
        overflow: hidden;
        position: relative;
    }

    &__image {
        width: 100%;

        @include when($large) {
            width: 50%;
            float: right;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    &__info {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        text-align: center;

        @include when($large) {
            position: absolute;
            height: 100%;
            width: 50%;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &--mirrored {
        .food {
            &__image {
                @include when($large) {
                    float: left;
                }
            }

            &__info {
                @include when($large) {
                    left: auto;
                    right: 0;
                }
            }
        }
    }

    &__inner {
        padding: 80px 0;
        max-width: 325px;
        margin: 0 auto;

        @include when($large) {
            padding: 0 calc(57vw / 14.4);
            max-width: 100%;
        }

        @include when($xlarge) {
            padding: 0 57px;
            max-width: 100%;
        }
    }

    &__name {
        font-family: $sans;
        font-weight: 400;
        color: #fff;
        font-size: 20px;
        line-height: 1;
        margin: 30px 0;

        @include when($large) {
            font-size: calc(30vw / 14.4);
            margin: 30px 0;
        }

        @include when($xlarge) {
            font-size: 30px;
            margin: 30px 0;
        }
    }

    &__title {
        font-family: $serif;
        color: #fff;
        font-size: 38px;
        line-height: 45px;
        margin: 0 0 50px;

        @include when($large) {
            font-size: calc(60vw / 14.4);
            margin: 0 0 calc(50vw / 14.4);
            line-height: 1;
        }

        @include when($xlarge) {
            font-size: 60px;
            margin: 0 0 50px;
        }
    }

    &__link {
        color: #ff0000;
        font-family: $serif;
        mix-blend-mode: difference;
        font-size: 20px;

        .material-icons {
            vertical-align: bottom;
            font-size: 20px;
        }

        @include when($large) {
            font-size: calc(34vw / 14.4);

            .material-icons {
                font-size: calc(34vw / 14.4);
            }
        }

        @include when($xlarge) {
            font-size: 34px;

            .material-icons {
                font-size: 34px;
            }
        }
    }

    &__target {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
