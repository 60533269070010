.site {
	min-width: 320px;
	width: 100%;
	overflow-x: hidden;
}










.debug .site::after {
	content: "size";
	position: fixed;
	bottom: 20px;
	right: 30px;
	font-family: sans-serif;
	font-size: 14px;
	color: rgba(255,255,255,.9);
	padding: 5px 7px 3px;
	z-index: 9999;
	
	@include when($small) {
		content: 'small';
		background-color: brown;
	}
	@include when($medium) {
		content: 'medium';
		background-color: green;
	}
	@include when($large) {
		content: 'large';
		background-color: blue;
	}
	@include when($xlarge) {
		content: 'xlarge';
		background-color: #7000ff;
	}
}