@font-face {
	font-family: 'Fakt Pro';
	src: font('FaktPro-Blond.woff2') format('woff2'),
		 font('FaktPro-Blond.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Fakt Pro';
	src: font('FaktPro-Normal.woff2') format('woff2'),
		 font('FaktPro-Normal.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Fakt Pro';
	src: font('FaktPro-Medium.woff2') format('woff2'),
		 font('FaktPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Fakt Pro';
	src: font('FaktPro-SemiBold.woff2') format('woff2'),
		 font('FaktPro-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Fakt Pro';
	src: font('FaktPro-Bold.woff2') format('woff2'),
		 font('FaktPro-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Fakt Pro';
	src: font('FaktPro-Black.woff2') format('woff2'),
		 font('FaktPro-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Eksell Display Web';
	src: font('EksellDisplayWeb-Medium.woff2') format('woff2'),
		 font('EksellDisplayWeb-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

//Material Icons
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: local('Material Icons'),
		 local('MaterialIcons-Regular'),
		 font('MaterialIcons-Regular.woff2') format('woff2'),
		 font('MaterialIcons-Regular.woff') format('woff');
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
	
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
	
	/* Support for IE. */
	font-feature-settings: 'liga';
}


//stacks
$sans: "Fakt Pro", sans-serif;
$serif: "Eksell Display Web", serif;
$monospace: monospace;

//weights
$bold: bold;

//sizes
$font-size: 16px;