.playlist {
	max-width: $max-width-inner;
	margin: 0 auto;
	
	&__container {
		
		background-color: $playlist-background-color;
		clear: both;
		overflow: hidden;
		position: relative;
		
		background-size: cover;
		
		margin: 0 20px;
		
		@include when($large) {
			height: 670px;
		}
	}
	
	&__player {
		
		margin: 0 auto;
		
		@include when($large) {
			float:left;
			margin-top: 55px;
			width: calc(100% - 360px);
			padding-left: 6.25vw;
		}
		
		@include when($xlarge) {
			padding-left: 80px;
		}
		
		& iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		&.--is-playing .plyr__control--overlaid {
			display: none;
		}
	}

	&--has-data &__pawrappa-da-wrapper {
		@include when($large) {
			padding: 10px;
			border: $playlist-border;
		}
	}

	&__iframe {
	}
	
	&__player .plyr--video {
		@include when($large) {
		}
	}
	
	&__title {
		color: $playlist-color;
		font-family: $sans;
		font-size: 27px;
		line-height: 1;
		font-weight: 600;
		margin: 20px;
		text-align: center;
		
		@include when($large) {
			text-align: left;
			margin: 50px 0 0;
			font-size: 40px;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-weight: 600;
		}
	}
	
	&__items {
		
		-ms-overflow-style: -ms-autohiding-scrollbar;
		-webkit-overflow-scrolling: touch;
		
		&::-webkit-scrollbar {
			display: none;
		}
		
		overflow-y: hidden;
		height:500px;
		padding: 20px 0;
		border: $playlist-border;
		border-left: 0;
		border-right: 0;
		 
		@include when($large) {
			overflow-y: auto;
			padding: 54px 0 120px;
			float:right;
			width: 360px;
			height: 100%;
			mask-image:linear-gradient(rgba(0,0,0,1) 80%, rgba(0,0,0,0) 98%);
			-webkit-mask-image:linear-gradient(rgba(0,0,0,1) 80%, rgba(0,0,0,0) 98%);
		}
		
	}
	
	&__item {
		overflow: hidden;
		clear: both;
		display: block;
		
		padding: 20px 0;
		
		border-top: $playlist-border;
		
		&:first-child {
			border-top-color: transparent;
		}
		
		&:last-child {
			padding-bottom: 120px;
		}
		
		&:hover {
		}
		
		&:nth-child(n+5) {
			display: none;
			
			@include when($large) {
				display: block;
			}
		}
		
		&--playing {
			background-color: rgba(255,255,255,.1);
		}
	}
	
	&__thumbnail {
		float:left;
		clear:left;
		width: 80px;
		height: 80px;
		margin-left: 40px;
		
		object-fit: cover;
	}
	
	&__name {
		float:right;
		color: $playlist-color;
		width: calc(100% - 140px);
		
		padding-right: 40px;
		
		font-family: $sans;
		font-size: 19px;
		line-height: 20px;
		
		position: relative;
		top: 40px;
		transform: translateY(-50%);
	}
}