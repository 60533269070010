﻿#PressPage {
    h2 {
        margin-left: 30px;
    }

    .press-item {
        width: 92px;
        float: left;
        margin: 0 0 30px 32px;
        text-align: center;
        cursor: pointer;
        height: 230px;

        &__link {
            text-decoration: none;
        }

        &__title {
            margin-top: 10px;
            position: relative;
            top: 10px;
            color: #000;
            font-size: 16px;
            line-height: 1.428571429;
            font-weight: bold;
        }

        &__issue {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 11px;
            color: #666;
            line-height: 13px;
            position: relative;
            top: 10px;
            display: block;
        }

        &__image {
            border: 1px solid #999;
        }
    }

    .clear {
        clear: both;
    }
}

#PressPageModal {
    background: #fff;

    .modal-dialog {
        width: 100%;
        height: 99%;
        margin: 0;
    }

    .close {
        color: #c6c6c6;
        position: absolute;
        right: 10px;
        text-decoration: none;
    }

    .modal-content {
        border: 0;
        box-shadow: none;
        height: 100%;
    }

    .modal-body {
        height: 80%;
    }

    #itemContainer {
        text-align: center;
        height: 100%;
    }

    img {
        height: 90%;
        width: auto;
    }

    #itemContainer a {
        color: #9b9c9e;
        background: url(/images/pdf.jpg) no-repeat right top;
        padding: 5px 25px 5px 0;
    }
}
