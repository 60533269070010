.text {
	max-width: $max-width-text;
	margin: 0 auto;
	
	& a:link,
	& a:visited,
	& a:hover {
		color: $yellow2;
		font-family: $serif;
		text-decoration: none;
		
		.yogi & {
			color: $blue;
		}
	}
	
	&__container {
		margin: 0 40px;
		
		@include when($large) {
			margin:0;
		}
	}
	
	&__title {
		font-family: $serif;
		font-size: 36px;
		font-weight: bold;
		margin: 60px 0 10px;
	}
	
	& hr {
		background-image: image('separator.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 47px 20px;
		height: 20px;
		margin: 60px 0;
		border: none;
		
		.yogi & {
			background-image: image('assets/masked-text-tiled.png');
			background-attachment: fixed;
			background-size: contain;
			background-repeat: repeat;
			
			height: 44px;
			width: 44px;
			position: relative;
			margin: 60px auto;
			
			&::after {
				content:"";
				position: absolute;
				top: -3px;
				left: -3px;
				width: 50px;
				height: 50px;
				background-image: image('mandala_mask.svg');
				background-size: cover;
			}
		}
	}
	
	&__intro {
		font-family: $sans;
		line-height: 1.5;
		font-size: 20px;
		
		@include when($large) {
			font-size: 30px;
			line-height: 44px;
		}
	}
	
	&__initial {
		font-family: $serif;
		float:left;
		font-size: 32px;
		font-weight: bold;
		text-align: center;
		line-height: 1;
		margin: 0 20px 10px 0;
		padding: 24px 0 0 0;
		width: 80px;
		height: 80px;
		background-image: image('assets/masked-text-tiled.png');
		background-attachment: fixed;
		position: relative;
		display: block;
		color:#fff;
		
		@include when($large) {
			font-size: 60px;
			width: 120px;
			height: 120px;
			padding: 30px 0 0 0;
		}
		
		&::after {
			content:"";
			width: 60px;
			height: 60px;
			border: 2px solid #fff;
			top: 9px;
			left: 9px;
			position: absolute;
			display: block;
			
			@include when($large) {
				font-size: 58px;
				width: 100px;
				height: 100px;
				top: 9px;
				left: 9px;
			}
		}
	}
	
	&__paragraph {
		font-family: $sans;
		font-size: 15px;
		line-height: 1.6;
		
		@include when($large) {
			font-size: 20px;
			line-height: 1.5;
		}
	}
	
	&__title + &__paragraph {
		margin-top: 10px;
	}
	
	&__quote {
		font-size: 36px;
		line-height: 1;
		color: #000;
		font-weight: bold;
		font-family: $serif;
		background:#fff;
		mix-blend-mode: multiply;
		position: relative;
		display: inline-block;
		margin: 20px 0;
		word-break: keep-all;
		
		@include when($medium) {
			font-size: 45px;
		}
		
		@include when($large) {
			font-size: 60px;
		}
		
		&::before {
			content:"";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-image: image('assets/masked-text-tiled.png');
			background-attachment: fixed;
			pointer-events: none;
			mix-blend-mode: screen;
		}
	}

	@at-root .--mask-bg-1 {
		& .text__quote::before,
		& .text__initial,
		.yogi & .text hr {
			background-image: image('mask_bg_1.png');
		}
	}
	@at-root .--mask-bg-2 {
		& .text__quote::before,
		& .text__initial,
		.yogi & .text hr {
			background-image: image('mask_bg_2.png');
		}
	}
	@at-root .--mask-bg-3 {
		& .text__quote::before,
		& .text__initial,
		.yogi & .text hr {
			background-image: image('mask_bg_3.png');
		}
	}
	@at-root .--mask-bg-4 {
		& .text__quote::before,
		& .text__initial,
		.yogi & .text hr {
			background-image: image('mask_bg_4.png');
		}
	}
	@at-root .--mask-bg-5 {
		& .text__quote::before,
		& .text__initial,
		.yogi & .text hr {
			background-image: image('mask_bg_5.png');
		}
	}
	@at-root .--mask-bg-6 {
		& .text__quote::before,
		& .text__initial,
		.yogi & .text hr {
			background-image: image('mask_bg_6.png');
		}
	}
	@at-root .--mask-bg-7 {
		& .text__quote::before,
		& .text__initial,
		.yogi & .text hr {
			background-image: image('mask_bg_7.png');
		}
	}
	@at-root .--mask-bg-8 {
		& .text__quote::before,
		& .text__initial,
		.yogi & .text hr {
			background-image: image('mask_bg_8.png');
		}
	}
}