.image {
	position: relative;
	width: 100%;
	max-width: $max-width-inner;
	margin: 50px auto 30px;
	
	@include when($large) {
		margin: 100px auto;
	}
	
	& img {
		max-width: 100%;
		height: auto;
		overflow: hidden;
		
		@include when($large) {
			
		}
	}

	&__overlay {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
	}
	
	//--small
	
	&--small {
		text-align: center;
		
		margin: 30px auto;
		
		@include when($large) {
			margin: 100px auto;
		}

		
	}
	
	&--small img {
		width: 980px;
		margin: 0 auto;
		max-width: 100%;
	}
	
	//--framed
	
	&--framed {}
	
	&--framed img {
		object-fit: cover;
		width: 100%;
		height: 375px;
		display: block;
		
		@include when($large) {
			width: 100%;
			height: auto;
		}
	}
	
	&--framed &__container {
		padding: 45px;
		margin: 0;
		position: relative;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		
		@include when($large) {
			padding: 150vw / $xlarge-vw;
			margin: 0 20px;
		}
		
		@include when($xlarge) {
			padding: 150px;
		}
	}
	
	&--framed &__overlay {
		padding: 0 50px;
		
		@include when($large) {
			padding:0 (150vw / $xlarge-vw);
		}
		
		@include when($xlarge) {
			padding:0 150px;
		}
	}	
	
	&--framed &__title {
		font-family: $serif;
		text-transform: uppercase;
		font-size: 27px;
		font-weight: bold;
		text-align: center;
		color: #fff;
		line-height: 1;

		@include when($large) {
			font-size: 60vw / $xlarge-vw;
		}

		@include when($xlarge) {
			font-size: 60px;
		}
	}
	
	&--framed hr {
		background-image: image('separator_white.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 25px auto;
		padding: 20px 0;
		border:0;
		
		@include when($large) {
			background-size: 47px auto;
			padding: (35vw / $xlarge-vw) 0;
		}
		
		@include when($xlarge) {
			padding: 35px 0;
		}
		
		.yogi & {
			background-image: image('separator_white_yogi.svg');
		}
	}
	
	&--framed &__desc {
		color: #fff;
		text-align: center;
		font-family: $sans;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.16em;
		
		@include when($large) {
			font-size: 17vw / $xlarge-vw;
			line-height: 20vw / $xlarge-vw;
		}
		
		@include when($xlarge) {
			font-size: 17px;
			line-height: 20px;
		}
	}
	
	
	
	//--acidframed
	
	$acidframed-width: 940px;
	$acidframed-vw-width: 9.4;
	
	&--acidframed {
	}
	
	&--acidframed img {
		
		width: 100%;
		max-width: $max-width-text;
		height: auto;
		
		@include when(only screen and (min-width: $acidframed-width)) {
			width: 640px;
		}
	}
	
	&--acidframed &__container {
		padding: 0 40px;
		text-align: center;

		@include when(only screen and (min-width: $acidframed-width)) {
			padding: 0;
			width: 940px;
			margin: 0 auto;
		}
	}
	
	&--acidframed &__overlay {
		mix-blend-mode: multiply;
	}
	
	&--acidframed &__title {
		text-align: center;
		font-family: $sans;
		color: #ef2f4d;
		font-weight: 500;
		text-transform: uppercase;
		font-size: 150vw / $acidframed-vw-width;

		@include when(only screen and (min-width: $acidframed-width)) {
			font-size: 150px;
		}
	}
	
	&--acidframed &__desc {
		text-align: center;
		font-family: $sans;
		color: #ef2f4d;
		font-size: 40vw / $acidframed-vw-width;
		width: calc(100% - 80px);
		max-width: 100%;
		margin: 0 auto;
		line-height: 1.25;
		
		@include when(only screen and (min-width: $acidframed-width)) {
			font-size: 40px;
			width: 780px;
		}
	}
	
	
	
	//--bigtext
	
	$bigtext-width: 980px;
	$bigtext-vw-width: 9.8;
	
	&--bigtext {
		margin-bottom: 60px;
	}
	
	&--bigtext img {
		object-fit: cover;
		width: auto;
		max-width: 100%;
		height: auto;
		min-height: 440px;
		
		@include when(only screen and (min-width: $bigtext-width)) {
			width: $bigtext-width;
			height: auto;
		}
	}
	
	&--bigtext &__container {
		padding: 0 40px;
		text-align: center;
		
		@include when(only screen and (min-width: $bigtext-width)) {
		}
	}
	
	&--bigtext &__title {
		text-align: center;
		font-family: $sans;
		color: rgba(255,255,255,1);
		-webkit-text-fill-color: rgba(255,255,255,0);
		-webkit-text-stroke: 2px #fff;
		text-transform: uppercase;
		font-size: 120vw / $bigtext-vw-width;
		line-height: 100vw / $bigtext-vw-width;
		font-weight: bold;
		
		@include when(only screen and (min-width: $bigtext-width)) {
			font-size: 140px;
			-webkit-text-stroke: 3px #fff;
			line-height: 120px;
		}
	}

}