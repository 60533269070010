.sidebar {
	
	&--is-open {
	}
	
	&__overlay {
		background-color: rgba(0,0,0,0);
		pointer-events: none;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: fixed;
		z-index: $z-index-sidebar-overlay;
		transition: background-color .2s;
	}
	
	&--is-open &__overlay {
		background-color: rgba(0,0,0,.6);
		pointer-events: auto;
	}
	
	&__container {
		position: absolute;
		width: 375px;
		max-width: 100%;
		height: 100vh;
		right: -375px;
		top: 0;
		background-color: #fff;
		background-size: cover;
		box-shadow: 0 0 80px rgba(0,0,0,0);
		
		transition: right .3s, box-shadow .3s;
		z-index: $z-index-sidebar-container;
		
		overflow-y: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;

		transform: translate3d(0,0,0);
		z-index: 1051;
		pointer-events: none;
	}
	
	&--is-open &__container {
		right: 0;
		pointer-events: auto;
		box-shadow: 0 0 80px rgba(0,0,0,.5);
	}
	
	&__toggle {
		position: fixed;
		top: 0;
		right: 0;
		width: 65px;
		height: 65px;
		display: block;
		color: #fff;
		z-index: 10;
		
		&:hover {
			color:#fff;
			background-color: rgba(255,255,255,.2);
		}
		
		& span {
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		
		& .material-icons {
			font-size: 32px;
		}
	}
	
	&__logo {
		width: $logo-width;
		height: $logo-height;
		overflow: hidden;
		text-indent: -100vw;
		margin: 43px auto 33px;
		
		background-image: image('logo.svg');
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
		
		.yogi & {
			background-image: image('separator_white_yogi.svg');
		}
		
		& a {
			display: block;
			height: 100%;
			margin: 0;
			padding: 0;
			color: rgba(0,0,0,0);
			font-size: 1px;
		}
	}
	
	&__items {
		list-style: none;
		padding: 0;
		margin: 10px 0;
	}
	
	
	&__item--title {
		display: block;
		line-height: 40px;
		color: #aaa;
		text-align: center;
		font-size: 16px;
		font-family: "Fakt Pro", sans-serif;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 2px;
		margin-top: 50px;
	}
	
	&__item a {
		display: block;
		color: #fff;
		text-align: center;
		font-size: $font-size-large;
		text-decoration: none;
		font-family: $sans;
		
		.sidebar__items--main & {
			font-family: $serif;
			font-size: 32px;
			line-height: 1.88;
		}
		.sidebar__items--extra & {
			font-family: $sans;
			font-size: 16px;
			line-height: 2.5;
			letter-spacing: 2px;
			font-weight: bold;
			text-transform: uppercase;
		}
		
		&:hover {
		}
	}
	
	& hr {
		background-image: image('separator.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 24px 12px;
		height: 12px;
		margin: 12px 0;
		border: none;
		
		.yogi & {
			background-image: image('separator_white_yogi.svg');
			background-size: 40px auto;
			height: 40px;
		}
	}
	
	.yogi &__logo + hr {
		display: none;
	}
	
	&__social {
		margin: 50px 0;
		text-align: center;
		
		& a {
			width: 32px;
			height: 32px;
			display: inline-block;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			overflow: hidden;
			text-indent: -100vw;
			margin: 0 10px;
		}
	}

	&__social &__facebook {
		background-image: image('facebook_white.svg');
		background-size: 16px 31px;
		margin-right: 5px
	}
	&__social &__instagram {
		background-image: image('instagram_white.svg');
		background-size: 30px 30px;
	}
	&__social &__vimeo {
		background-image: image('vimeo_white.svg');
		background-size: 32px 28px;
	}
	
}