// --------------------------------------------------------------
// Time
// --------------------------------------------------------------

.plyr__time {
  font-size: $plyr-font-size-time;
}

// Media duration hidden on small screens
.plyr__time + .plyr__time {
  // Add a slash in before
  &::before {
    content: '\2044';
    margin-right: $plyr-control-spacing;
  }

  @media (max-width: calc(#{$plyr-bp-md} - 1)) {
    display: none;
  }
}
