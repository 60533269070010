.quiz {
	position: relative;
	width: 100%;
	max-width: $max-width-inner;
	margin: 50px auto 80px;
	
	&__container {
		max-width: 100vw;
		margin: 0 20px;
		
		@include when($large) {
			margin:0;
		}
	}
	
	.question {
		display: none;
		
		&--current {
			display: block;
		}
		
		&__image {
			width: 100%;
			position: relative;
			
			@include when($large) {
				max-width: 960px;
				margin: 0 auto;
			}
			
			img {
				width: 100%;
				height: auto;
			}
		}
		
		&__form {
			position: relative;
			width: 100%;
			max-width: $max-width-text;
			margin: 50px auto 30px;
		}
		
		.numbering {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left:0;
			width: 100%;
			text-align: center;
			color: #fff;
			font-weight: 500;
			font-family: $sans;
			text-transform: uppercase;
			font-size: 20vw;
			
			mix-blend-mode: exclusion;
			
			@include when($large) {
				font-size: 180px;
				letter-spacing: 0.04em;
			}
			
			&__current,
			&__total {
				padding: 0 5px;
			}
			
			&__meta {
			}
		}
		
		&__title {
			font-family: $serif;
			font-size: 36px;
			font-weight: bold;
			margin: 20px 0 10px;
		}
		
		&__answers {
			list-style: none;
			padding: 0;
			margin: 0;
			
			
		}
		
		.answer {
			position: relative;
			font-size: 20px;
			width: calc(100% + 30px);
			left: -15px;
			
			border-bottom: 1px solid rgba(132, 91, 11, 0.1);
			
			&:first-child {
				border-top: 1px solid rgba(132, 91, 11, 0.1);
			}
			
			&:hover {
				background-color: rgba(253, 177, 20, 0.05);
			}
			
			&--expanding {
				height: 70px;
				transition: height .2s;
				
				&.--is-checked {
					height: 110px;
				}
			}
			
			&__inner {
				position: relative;
			}	

			&__checkbox {
				position: relative;
				top: 18px;
				left: 15px;
			}
			
			&__checkbox input {
				width: 30px;
				height: 30px;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
				opacity: 0;
				z-index: -1;
			}
			
			.material-icons {
				position: absolute;
				top: 0;
				left: 0;
				font-size: 24px;
				display: inline-block;
				width: 30px;
				height: 30px;
				line-height: 26px;
				border-radius: 4px;
				background-color: #fff;
				color: rgba(0,0,0,0);
				text-align: center;
				pointer-events: none;
				box-sizing: border-box;
				border: 2px solid #fdb114;
				transition: background-color .1s .1s, color .1s;
			}
			
			&.--is-checked .material-icons {
				background-color: #fdb114;
				color: #fff;
				transition: color .1s .1s, background-color .1s;
			}
			
			&__checkbox--radio {
				
				.material-icons {
					border-radius: 30px;
				}
			}
			
			&__label {
				padding: 15px 15px 15px 60px;
				display: block;
				-moz-user-select: none;
				-webkit-user-select: none;
				-ms-user-select: none;
				user-select: none;
				cursor: pointer;
				position: relative;
				z-index: 555;
			}
						
			&__title {
				font-size: 20px;
				line-height: 40px;
				pointer-events: none;
			}
			&__desc {
				color: rgba(0,0,0,.5);
				font-size: 16px;
				line-height: 24px;
				pointer-events: none;
			}
			
			&__label input[type=text] {
				color: rgba(0,0,0,.8);
				font-size: 16px;
				line-height: 24px;
				opacity: 0;
				
				transition: opacity .1s;
			}
			
			&.--is-checked input[type=text] {
				opacity: 1;

				transition: opacity .2s .150ms;
			}
			
			&--single-input {
				
				overflow: hidden;
				
				padding: 15px;
				
				&:hover {
					background-color: rgba(0,0,0,0);
				}
				
				input {
					width: 90px;
					line-height: 45px;
					float: left;
					padding-left: 10px;
					padding-right: 35px;
				}
				
				.single-input-value {
					float: left;
					display: block;
					line-height: 53px;
					position: relative;
					left: -30px;
					color:rgba(0,0,0,.5);
				}

			}
			
		}
		
		@at-root {
			.quiz .inputAllOptional .answer--single-input {
				
				input {
					width: 100%;
					line-height: 45px;
					float:left;
					text-align: left;
					padding-left: 10px;
					padding-right: 10px;
				}
				
				.single-input-value {
					display: none;
				}
			}
		}
		
		&__nav {
			overflow: hidden;
			height: 50px;
			font-size: 20px;
			line-height: 50px;
			margin-top: 30px;
			
			a {
				color: #000;
				text-decoration: none;
			}
			
			.back,
			.fwd {
				width: 48%;
				display: block;
			}
			
			.back::before,
			.fwd::after {
				content: "";
				top: -3px;
				position: relative;
				display: inline-block;
				width: 5px;
				height: 5px;
				border: 2px solid #000;
				border-width: 0 0 2px 2px;
				transform: rotate(45deg);
			}
			
			.back::before {
				right: 5px;
			}
			
			.fwd::after {
				left: 5px;
				transform: rotate(-135deg);
			}
			
			.back {
				width: 48%;
				float:left;
				padding-left: 10px;
			}
			
			.fwd {
				width: 48%;
				float:right;
				text-align: right;
				padding-right: 10px;
			}
		}
		
	}
	
}