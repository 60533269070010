.video {
	max-width: $max-width-inner;
	margin: 30px auto;
	
	@include when($large) {
		margin: 100px auto;
	}
	
	.page--front & {
		margin-bottom:0;
	}
	
	&__container {
		margin: 0 20px;
	}
	
	&__player {
		background-color: #000;
		margin: 0 auto;
		
		width: 100%;
		padding-bottom: 56.25%;
		
		& iframe {
			width: 100%;
			height: 100%;
			display: none;
		}
	}
	
	@include when($small-only) {

		& .plyr__video-wrapper {
			padding-bottom: 90% !important;
			
			& > div {
				transform: translateY(-31%) !important;
			}
		}

	}

}