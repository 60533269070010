.toggle {
	
	&__button {
		display: block;
		width: $header-height;
		height: $header-height;
		line-height: $header-height;
		margin: 0;
		position: absolute;
		right: 0;
		top: 0;
		text-indent: -999999px;
		
		& span {
			width: 32px;
			height: 2px;
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			text-indent: -100vw;
			background-color: $header-color;
		}
				
		& span::before,
		& span::after {
			content:"";
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: inherit;
		}
		& span::before {
			top: -9px;
		}
		& span::after {
			top: 9px;
		}
		
		&:hover {
			background-color: rgba(255,255,255,.1);
		}
	}
	
	@at-root .page--sub &__button {
		& span {
			background-color: #000;
		}
	}

	
	&__booking {
		background-image: image('boka-bg.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		display: block;
		text-align: center;
		position: absolute;
		padding: 18px 0 0;
		width: 124px;
		height: 48px;
		color: #fff;
		text-align: center;
		font-family: $sans;
		font-size: 14px;
		line-height: 1;
		letter-spacing: 2px;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;
		white-space: nowrap;
		
		top: 16px;
		left: 50%;
		transform: translateX(-62px);
		
		@include when($xlarge) {
			left: auto;
			right: $header-height;
			transform: none;
		}
	}
}