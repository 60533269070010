.gallery {
	
	width: 100%;
	max-width: $max-width-inner;
	margin: 0 auto;
	padding-top: 20px;

	&__container {

		margin: 0;
		overflow: hidden;
		clear: both;
		padding: 0 40px;
		
		@include when($large) {
			padding:0 20px;
		}
	}
	
	&__photo {
		
		display: block;
		margin: 0 0 60px;
		
		& img {
			width: 100%;
			height: auto;
			margin: 0 auto;
			display: block;
		}
		
		@include when($large) {
			
			width: calc(50% - 40px);
			max-width: 600px;
			margin-top: 80px;
			margin-bottom: 0;
			
			&:nth-child(1),
			&:nth-child(2) {
				margin-top: 0;
			}
		
			&:nth-child(odd) {
				float:left;
				clear:left;
			}

			&:nth-child(even) {
				float:right;
				clear:right;
			}
			&:nth-child(even)::after {
				display: block;
				clear: both;
			}
			
			& img {
			}
		}

	}
	&__slides,
	&__controls {
		display: none;
	}
	&--is-open &__slides,
	&--is-open &__controls {
		display: block;
	}
	
	&__overlay {
		background-color: rgba(0,0,0,0);
		pointer-events: none;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: fixed;
		z-index: $z-index-gallery-overlay;
		transition: background-color .2s;
	}
	
	&--is-open &__overlay {
		background-color: rgba(255,255,255,.8);
		pointer-events: auto;
	}
	
	&__slides {
		z-index: $z-index-gallery-slides;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0%;
		padding: 0;
		transition: left .2s;
		pointer-events: none;
		
		&--is-loading::after {
			content:"";
			position: fixed;
			top: 50%;
			left: 50%;
			
			width: 60px;
			height: 60px;
			margin: -30px 0 0 -30px;
			background-color: $yellow2;
			
			border-radius: 100%;  
			animation: sk-scaleout 1.0s infinite ease-in-out;
			
			.yogi & {
				background-color: $blue;
			}
		}
		
		@keyframes sk-scaleout {
			0% {
				transform: scale(0);
			}
			100% {
				transform: scale(1.0);
				opacity: 0;
			}
		}
	}
	
	&__slide {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 40px 20px;
		text-align: center;
		
		@include when($large) {
			padding: 100px 130px;
		}
		
		& img {
			width: auto;
			max-width: 100%;
			height: auto;
			max-height: 80%;
			position: relative;
			top:50%;
			transform: translateY(-50%);
			pointer-events: auto;
			
			@include when($large) {
				width: auto;
				max-width: 100%;
				max-height: 95%;
			}
		}
	}
	
	&__controls {
		font-family: $sans;
		font-size: 40px;
		font-weight: 500;
		position: fixed;
		z-index: $z-index-gallery-controls;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		
		@include when($large) {
			font-size: 90px;
		}
		
		& a {
			text-decoration: none;
		}
	}
	
	&__close,
	&__prev,
	&__next {
		pointer-events: auto;
	}
		
	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		
		@include when($large) {
			right: 50px;
		}
		
		& a {
			color: $yellow2;
			
			.yogi & {
				color: $blue;
			}
		}
	}
	
	&__prev,
	&__next {
		width: 50px;
		height: 100%;
		position: absolute;
		top:0;
		
		@include when($large) {
			width: 90px;
		}
		
		& a {
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff;
		}
	}
	
	&__prev {
		left: 0;
		
		@include when($large) {
			left: 50px;
		}
		
		& a {
			transform: translate(-50%, -50%) rotate(-90deg);
		}
	}
	
	&__next {
		right: 0;
		
		@include when($large) {
			right: 50px;
		}
		
		& a {
			transform: translate(-50%, -50%) rotate(90deg);
		}
	}
	
	&__controls .--is-disabled a {
		color: rgba(0,0,0,.3);
		cursor: default;

		.yogi & {
			color: rgba(0,0,0,.3);
		}
	}
	&__controls a {
		color: $yellow2;
		
		.yogi & {
			color: $blue;
		}
	}
}