* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

}

.hidden {
	display: none;
	
	&--mobile {
		@include when($medium) {
			display: block;
		}
	}
}