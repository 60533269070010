.contact {
	width: 100%;
	font-size: 26px;
	font-family: $sans;
	margin-bottom: 60px;
	
	color: $footer-color;
	& a {
		color: $footer-color;
	}
	
	@include when($medium) {
		font-size: 28px;
	}
	
	@include when($large) {
		font-size: $footer-font-size;
		float:left;
		width: 50%;
	}
	
	&__title {
		margin: 0;
		font-size: 28px;
		text-transform: uppercase;
		letter-spacing: normal;
		
		@include when($large) {
			font-size: $footer-font-size;
			letter-spacing: .2em;
		}
	}
	
	&__items {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	
	&__item, & p {
		margin-top: $footer-row-margin;
	}
}