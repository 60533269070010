.footer {

	width: 100%;
	padding: 60px 0 0;
	margin: 60px 0 0;
	
	&__container {
		max-width: $max-width-inner;
		margin: 0 auto;
		padding: 0 20px 50px;
		overflow: hidden;
		
		@include when($large) {
		}
	}
}