.order {
	
	max-width: $max-width-inner;
	margin: 0 auto;
	
	&__container {
		background-image: image('assets/destinations.png');
		background-size: cover;
		padding: 100px 30px;
		
		.yogi & {
			background-image: image('assets/destinations-background-min.png');
		}
		
		@include when($large) {
			padding: 180px 50px;
		}
	}
	
	&__image {
		max-width: 60%;
		width: 200px;
		height: 167px;
		
		margin: 0 auto;

		background-image: image('shaka_noarrow.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		
		&--success {
			background-image: image('shaka_noarrow.svg');
			
			.yogi & {
				background-image: image('separator_white_yogi.svg');
			}
		}
	}
	
	&__title {
		font-family: $serif;
		font-size: 80px;
		text-align: center;
		color: #fff;
		margin: 30px 0;
		font-weight: normal;
		
		@include when($large) {
			font-size: 100px;
		}
	}
	
	&__message {
		font-family: $sans;
		font-size: 26px;
		font-weight: 500;
		text-align: center;
		color: #fff;
		
		@include when($large) {
			font-size: 30px;
		}
	}
}